import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'

export default ({site, pageContext: {frontmatter}, children}) => (
  <Layout site={site} frontmatter={frontmatter}>
    <SEO frontmatter={frontmatter} />
    <Container noVerticalPadding={frontmatter.noVerticalPadding === undefined ? true : frontmatter.noVerticalPadding}>
      {children}
    </Container>
  </Layout>
)

export const pageQuery = graphql`
  fragment site on Site {
    siteMetadata {
      title
      description
      author {
        name
      }
      keywords
    }
  }
`
